import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'

import { typography, colors, util } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const PageBg = styled(Section)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	${ ({ color }) => color ? `
		background: ${ colors[color] };
	` : `` }
`

const IntroTextSection = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	${ util.responsiveStyles('padding-top', 80, 60, 40, 20) }
	p {
		margin: 0;
		${ typography.bodyLarge }
		+ p {
			text-indent: 2em;
		}
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		max-width: 30em;
	}
`

const LogoWrapper = styled.div`
	width: 50px;
	flex-grow: 1;
	flex-shrink: 0;
	margin-top: -1px;
	margin-bottom: -3px;
	svg {
		dispaly: block;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	${ util.responsiveStyles('padding', 14, 12, 10, 10) }
`

class PageTemplate extends React.Component {
	state = {
		bgColor: false
	}

	setBgColor = color => {
		this.setState({ bgColor: color })
	}

	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const { bgColor } = this.state
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo

		return (
			<Fragment>
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				{/*<Header
					hasAtf={hasAtf}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          locations={page.slug}
				/>*/}
				<Wrapper>
					<LogoWrapper>
						<Logo/>
					</LogoWrapper>
					<IntroTextSection>
						<div>
							<p><em>Matthew Gordils</em> is a designer and frontend developer. He currently runs <em onMouseEnter={() => this.setBgColor('green')} onMouseLeave={() => this.setBgColor(false)}><Link to='https://hyperlinknyc.com/'>Studio Hyperlink</Link></em> where he designs and develops artful and intuitive websites.</p>
							<p>A New York native, Matthew completed his BFA in Graphic and Interactive Design from the Tyler School of Art. After working with clients in the fashion, fine art, and food industries, he moved on to work with early stage startups doing UI/UX work and development. Focused on merging classic design with cutting-edge development, Matthew continuously pushes interactive design to its peak.</p>
						</div>
					</IntroTextSection>
				</Wrapper>
				{/*sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
						/>
					)
				})*/}
				{/*<Footer {...site} setBgColor={this.setBgColor}/>*/}
				<PageBg color={bgColor}/>
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default PageTemplate
