import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Img from 'gatsby-image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { mq } from 'src/styles'

const FFSection = styled(Section)``

const ColumnWrapper = styled.div`
`

const arrangeMedia = {
  left: {
    gridSetup: {
      normal: '2 [11] 2 [11] 2',
      large: '2 [12] 2 [10] 2',
      extraLarge: '2 [13] 2 [9] 2'
    }
  },
  right: {
    gridSetup: {
      normal: '2 [11] 2 [11] 2',
      large: '2 [12] 2 [10] 2',
      extraLarge: '2 [13] 2 [9] 2'
    }
  },
  bleedLeft: {
    gridSetup: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2'
    }
  },
  bleedRight: {
    gridSetup: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2'
    }
  }
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement,
  mediaWidth,
  eyebrow,
  text,
  actions,
  paragraphSize,
  verticalAlignment,
  isFirstSection,
}) => {
  if (!mediaPlacement || mediaPlacement === null) {
    mediaPlacement = 'left'
  }
  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        large={arrangeMedia[mediaPlacement || 'left'].gridSetup[mediaWidth || 'normal']}
        rowGap={['7vw', '7vw', '80px']}
        vAlign={verticalAlignment}
        gridDirection={mediaPlacement.includes('right') || mediaPlacement.includes('Right') ? 'rtl' : 'ltr'}
      >
        <ColumnWrapper>
          {media.file.contentType === 'video/mp4' ? (
            <ScrollEntrance delay={0 /* TBD */}>
              <Video video={media} />
            </ScrollEntrance>
          ) : (
            <ScrollEntrance delay={0 /* TBD */}>
              <Img
                fluid={media.fluid}
                loading={isFirstSection ? 'eager' : 'lazy'}
              />
            </ScrollEntrance>
          )}
        </ColumnWrapper>

        <ColumnWrapper>
          <TextLockup
            entranceDelay={1}
            eyebrow={eyebrow}
            text={text}
            textSize={paragraphSize}
            actions={actions}
          />
        </ColumnWrapper>
      </Grid>
    </FFSection>
  )
}

FiftyFifty.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default FiftyFifty
