import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Img from 'gatsby-image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import { colors, mq } from 'src/styles'
import TextLockup from 'src/components/TextLockup'

const Wrapper = styled(Section)`
  ${ ({ overlayTextColor }) => overlayTextColor === 'dark' ? `
    color: ${ colors.textColor };
  ` : `
    color: ${ colors.bgColor };
  ` }
`

const WideMediaWrap = styled.div`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    position: relative;
    z-index: 2;
    height: ${ setHeight };
  ` : `` }
  > div {
    max-height: 100%;
  }
`

const ContentWrap = styled.div`
  position: relative;
`

const MediaResponsiveImage = styled(Image)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    height: ${ setHeight };
    > div {
      height: ${ setHeight };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const MediaImage = styled(Img)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    height: ${ setHeight };
    > div {
      height: ${ setHeight };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const MediaVideo = styled(Video)`
  ${ ({ setHeight }) => setHeight !== 'auto' ? `
    > div {
      height: ${ setHeight };
      min-height: 40vw;
      position: relative;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const OverlaySection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: ${ ({ verticalPlacement }) => {
    if (!verticalPlacement) return 'center'
    if (verticalPlacement === 'top') return 'flex-start'
    if (verticalPlacement === 'bottom') return 'flex-end'
    else return verticalPlacement
  } };
  text-align: ${ ({ overlayTextAlignment }) => overlayTextAlignment || 'left' };
`

function getHorizontalPlacementGridValues ({ fullWidth, horizontalPlacement }) {
  if (!fullWidth) {
    return {
      left: {
        medium: '2 [10] 2',
        large: '2 [8] 4',
        larger: '2 [6] 6'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '7 [5] 2',
        large: '7 [5] 2',
        larger: '7 [5] 2'
      }
    }[horizontalPlacement]
  } else {
    return {
      left: {
        medium: '1 [9] 4',
        large: '1 [8] 5',
        larger: '1 [6] 7'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '7 [6] 1',
        large: '7 [6] 1',
        larger: '7 [6] 1'
      }
    }[horizontalPlacement]
  }
}

const WideMedia = ({
  media,
  responsiveImage,
  width,
  theme,
  prevTheme,
  nextTheme,
  height,
  eyebrow,
  text,
  paragraphSize,
  actions,
  overlayPlacement,
  overlayTextAlignment,
  isFirstSection,
  overlayTextColor
}) => {
  if (!media) {
    return false
  }

  const fullWidth = width === 'fullWidth'

  const type = media.file.contentType.includes('video') ? 'video' : 'image'

  const heightValues = {
    auto: 'auto',
    fullHeight: '100vh',
    mediumHeight: '70vh',
    shortHeight: '50vh'
  }

  const [verticalPlacement, horizontalPlacement] = (overlayPlacement || 'center center').split(' ')
  const overlayGridSettings = getHorizontalPlacementGridValues({ fullWidth, horizontalPlacement })
  return (
    <Wrapper
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      setTheme={theme}
      padded={!fullWidth}
      overlayTextColor={overlayTextColor}
    >
      <WideMediaWrap setHeight={heightValues[height]}>
        <Grid small={fullWidth ? '[1]' : '1 [12] 1'}>
          <ContentWrap>
            {responsiveImage ? (
              <MediaResponsiveImage
                image={responsiveImage.image}
                small={responsiveImage.small}
                medium={responsiveImage.medium}
                setHeight={heightValues[height]}
                loading={isFirstSection ? 'eager' : 'lazy'}
              />
            ) : (
              type === 'image' ? (
                <MediaImage
                  fluid={media.fluid}
                  loading={isFirstSection ? 'eager' : 'lazy'}
                  setHeight={heightValues[height]}
                />
              ) : (
                <MediaVideo
                  video={media}
                  playing={true}
                  loop={true}
                  setHeight={heightValues[height]}
                  posterImage={media.posterImage}
                  autoplay={true}
                />
              )
            )}
            {text && (
              <OverlaySection
                padded={!fullWidth}
                overlayTextAlignment={overlayTextAlignment}
                verticalPlacement={verticalPlacement}
              >
                <Section padded>
                  <Grid
                    small={fullWidth ? '1 [12] 1' : '1 [10] 1'}
                    {...overlayGridSettings}
                  >
                    <TextLockup
                      text={eyebrow}
                      text={text}
                      textSize={paragraphSize}
                      actions={actions}
                    />
                  </Grid>
                </Section>
              </OverlaySection>
            )}
          </ContentWrap>
        </Grid>
      </WideMediaWrap>
    </Wrapper>
  )
}

WideMedia.defaultProps = {
  width: 'margins',
  height: 'auto'
}

export default WideMedia
