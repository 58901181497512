import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Video from 'src/components/Video'
import Img from 'gatsby-image'
import TextLockup from 'src/components/TextLockup'
import { mq, gridSettings } from 'src/styles'

const Wrapper = styled(Section)`
  text-align: ${ ({ alignment }) => alignment };
`

const MediaScrollEntrance = styled(ScrollEntrance)`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`

const ColumnWrapper = styled.div`
  .gatsby-image-wrapper,
  .video-wrapper {
    margin-bottom: 24px;
  }
`

const ColumnsGrid = styled(Grid)`
  ${ ({ alignment, vAlign, smallCols, mediumCols, largeCols, colGap }) => alignment === 'center' ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0 !important;
    align-items: ${ vAlign === 'bottom' ? 'flex-end' : vAlign };
    margin-left: calc(${ gridSettings.colSpacingSmall || colGap } / -2);
    margin-right: calc(${ gridSettings.colSpacingSmall || colGap } / -2);
    width: auto;
    ${ mq.mediumAndUp } {
      margin-left: calc(${ gridSettings.colSpacingMedium || colGap } / -2);
      margin-right: calc(${ gridSettings.colSpacingMedium || colGap } / -2);
    }
    ${ mq.largeAndUp } {
      margin-left: calc(${ gridSettings.colSpacingLarge || colGap } / -2);
      margin-right: calc(${ gridSettings.colSpacingLarge || colGap } / -2);
    }
    ${ ColumnWrapper } {
      width: ${ 100 / smallCols }%;
      padding-left: calc(${ gridSettings.colSpacingSmall || colGap }/2);
      padding-right: calc(${ gridSettings.colSpacingSmall || colGap }/2);
      ${ mq.mediumAndUp } {
        width: ${ 100 / mediumCols }%;
        padding-left: calc(${ gridSettings.colSpacingMedium || colGap }/2);
        padding-right: calc(${ gridSettings.colSpacingMedium || colGap }/2);
      }
      ${ mq.largeAndUp } {
        width: ${ 100 / largeCols }%;
        padding-left: calc(${ gridSettings.colSpacingLarge || colGap }/2);
        padding-right: calc(${ gridSettings.colSpacingLarge || colGap }/2);
      }
    }
  ` : `` }
`

const gridSetup = {
  1: '[1]',
  2: '[1] [1]',
  3: '[1] [1] [1]',
  4: '[1] [1] [1] [1]',
  5: '[1] [1] [1] [1] [1]',
  6: '[1] [1] [1] [1] [1] [1]'
}

const Columns = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  alignment,
  verticalAlignment = 'top',
  id,
  desktopColumnCount = 3,
  tabletColumnCount = 2,
  mobileColumnCount = 1
}) => (
  <Wrapper
    className={className}
    setTheme={theme}
    prevTheme={prevTheme}
    nextTheme={nextTheme}
    alignment={alignment === null ? 'left' : alignment}
  >
    <Grid small="1 [12] 1">
      <ColumnsGrid
        small={gridSetup[mobileColumnCount]}
        medium={gridSetup[tabletColumnCount]}
        large={gridSetup[desktopColumnCount]}
        smallCols={mobileColumnCount}
        mediumCols={tabletColumnCount}
        largeCols={desktopColumnCount}
        vAlign={verticalAlignment}
        alignment={alignment}
        rowGap={['7vw', '7vw', '80px']}
        colGap="2vw"
      >
        {columns.map((column, index) => {
          return (
            <ColumnWrapper
              alignment={alignment === null ? 'left' : alignment}
              index={index}
              colCount={columns.length}
              key={column.id + '-' + index + '-' + id}
            >
              {column.media && (
                <MediaScrollEntrance>
                  {column.media.file.contentType.includes('video') ? (
                    <Video video={column.media} />
                  ) : (
                    <Img fluid={column.media.fluid} />
                  )}
                </MediaScrollEntrance>
              )}
              <TextLockup
                entranceDelay={column.media ? 1 : 0}
                eyebrow={column.eyebrow}
                text={column.text}
                textSize={column.paragraphSize}
                actions={column.actions}
              />
            </ColumnWrapper>
          )
        })}
      </ColumnsGrid>
    </Grid>
  </Wrapper>
)

export default Columns
