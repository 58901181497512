import React from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import { MdArrowForward } from 'react-icons/md'

import { typography, colors, mq, animations } from 'src/styles'

const BigLink = styled.div`
	font-size: 9vw;
	font-family: ${ typography.secondaryFont };
	letter-spacing: -.03em;
	font-weight: normal;
	display: inline-block;
	align-items: center;
	line-height: 1em;
	margin-left: -.05em;
	position: relative;
	span {
		display: inline-block;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -.2em;
		left: 0;
		width: 100%;
		height: 2px;
		background: currentcolor;
		transform: scaleX(0);
		transform-origin: right center;
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	${ mq.mediumAndUp } {
		font-size: 7vw;
		&:after {
			height: 3px;
		}
	}
	${ mq.largerAndUp } {
		font-size: 5vw;
		&:after {
			height: 4px;
		}
	}
`

const Wrapper = styled(Link)`
	display: flex;
	align-items: flex-end;
	position: relative;
	height: 60vh;
	min-height: 400px;
	padding-bottom: 10px;
	z-index: 1;
	${ ({ color }) => color !== 'transparent' ? `
		background: linear-gradient(0deg, ${ color } 0%, ${ rgba(color, 0) } 100%);
	` : `` }
	${ mq.mediumAndBelow } {
		height: 100vw;
	}
	&:hover {
		${ BigLink } {
			&:after {
				transform-origin: left center;
				transform: scaleX(1);
			}
		}
	}
`

const ExternalArrowIcon = styled(MdArrowForward)`
	transform: rotate(-45deg);
	width: 1.125em;
	height: 1.125em;
	margin-right: -.275em;
	margin-bottom: -.3em;
	display: inline-block;
`

const TwoColorGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		background: radial-gradient(circle at -100% 100%, ${ rgba(colors.red, 0) } 0%, ${ colors.green } 100%);
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;
		background: radial-gradient(circle at -100% 100%, ${ rgba(colors.red, 0) } 0%, ${ colors.green } 100%);
	}
`

const Footer = ({ setBgColor }) => {
	let gradientColor = colors.blue
	let isHalloween = false
	let isChristmas = false
	let isEarthDay = false
	if (isHalloween) {
		gradientColor = '#D3661A'
	}
	if (isChristmas) {
		gradientColor = colors.red
	}
	if (isEarthDay) {
		gradientColor = colors.green
	}
	return (
		<div onMouseEnter={() => setBgColor('blue')} onMouseLeave={() => setBgColor(false)}>
			<Wrapper color={gradientColor} to='https://gordilsandwillis.com'>
				<Section>
					<Grid
						small="1 [12] 1"
						medium="1 [24] 1"
						large="1 [24] 1"
					>
						<div>
							<BigLink><span>Gordils&Willis.com</span><ExternalArrowIcon /></BigLink>
						</div>
					</Grid>
				</Section>
			</Wrapper>
		</div>
	)
}

export default Footer
