import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, colors, mq } from 'src/styles'
import BalanceText from 'react-balance-text'

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ mq.mediumAndBelow } {
		display: block;
	}
`
const TextContainer = styled(ScrollEntrance)`
	text-align: ${ ({ alignment }) => alignment };
	width: 100%;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
`

const Eyebrow = styled.h6`
	${ typography.eyebrow }
`

const Headline = styled.h3`
	${ ({ headlineSize }) => typography[headlineSize] }
`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: inline-block;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 8px;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
`

const TextLockup = ({
		theme,
		eyebrow,
		headline,
		headlineSize,
		text,
		textSize,
		actions,
		className,
		icon,
		alignment,
		additions,
		entranceDelay,
		transitionIn
	}) => {
	return (
		<Wrapper className={className} alignment={alignment}>
			<div>
				<TextContainer alignment={alignment} delay={entranceDelay} transitionIn={transitionIn}>
					<ConditionalRender condition={icon && icon.svgContent}>
						<div style={{ margin: 'auto', width: 50, height: 50, marginBottom: '1em' }} dangerouslySetInnerHTML={{ __html: icon && icon.svgContent }}/>
					</ConditionalRender>
					<ConditionalRender condition={eyebrow}>
						<div>
							<Eyebrow>{eyebrow}</Eyebrow>
						</div>
					</ConditionalRender>

					{headline && (
						<Headline headlineSize={headlineSize}>{headline}</Headline>
					)}

					{text && text.json &&
						<Text textSize={textSize} alignment={alignment}><ContentfulRichText richText={text.json}/></Text>
					}

					{typeof text === 'string' &&
						<Text textSize={textSize} alignment={alignment}><p>{text}</p></Text>
					}

					{text && typeof text !== 'string' && !text.json &&
						<Text textSize={textSize} alignment={alignment}>{text}</Text>
					}

					{actions && (
						<ButtonActions buttons={actions} alignment={alignment}>
							{actions.map((action, index) => {
								if (action.__typename === 'ContentfulButton') {
									return (
										<ActionWrapper>
											<Button
												key={'button-' + index}
												setTheme={action.theme}
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
											>
												{action.label}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper>
											<TextLink
												key={'button-' + index}
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
											>
												{action.label}
											</TextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: 'inherit',
	textSize: 'body',
	entranceDelay: 0,
	transitionIn: true
}

export default TextLockup
